import { render, staticRenderFns } from "./AlarmProcessing.vue?vue&type=template&id=fabd9e68&scoped=true"
import script from "./AlarmProcessing.vue?vue&type=script&lang=js"
export * from "./AlarmProcessing.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fabd9e68",
  null
  
)

export default component.exports